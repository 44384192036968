/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav, Container, Badge, Dropdown } from "react-bootstrap";
import logoPM from "../images/logo-pinjam-modal.svg";
import iconPM from "../images/logo-pinjam-modal-small.png";

// icons
import {
  FaCaretUp,
  FaCaretDown,
  FaShoppingCart,
  FaTasks,
  FaRegUserCircle,
  FaSignOutAlt,
  FaReceipt,
} from "react-icons/fa";

import {
  RiDashboardFill,
  RiFileList2Fill,
  RiFileHistoryFill,
  RiContactsFill,
  RiInformationFill,
} from "react-icons/ri";

// redux
import { useDispatch, useSelector } from "react-redux";
import { getLenderProfile } from "../redux/action/profile_action";
import { getLenderActivity } from "../redux/action/lender_activity_action";

// util
import util from "../helper/util";
import PrivyPopUp from "./PrivyPopUp";
import PrivyRegisterPopUp from "./PrivyRegisterPopUp";

const DashboardNavbar = () => {
  // function
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };
  const handleResize = useCallback(() => {
    setWindowDimensions(getWindowDimensions());
  }, []);

  // window resolutions
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // navbar dropdown caret
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPrivyModal, setPrivyModal] = useState(false);
  const [showPrivyRegisterModal, setPrivyRegisterModal] = useState(false);

  // redux
  const dispatch = useDispatch();
  const { token, lenderActivity, mobileNumber } = useSelector((state) => ({
    token: state.user.token,
    lenderActivity: state.lenderActivity,
    mobileNumber: state.profile.mobileNumber,
  }));

  // component did mount
  useEffect(() => {
    dispatch(getLenderProfile());
    window.addEventListener("resize", handleResize);
    // component will unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, handleResize]);

  useEffect(() => {
    dispatch(getLenderActivity());
  }, [window.location.pathname]);

  useEffect(() => {
    if (lenderActivity.documentType && !showPrivyModal) {
      setPrivyModal(true);
    }
  }, [lenderActivity.documentType]);

  useEffect(() => {
    if (
      lenderActivity.lenderStatus === "ACTIVE" &&
      lenderActivity.isPrivyParameterActive &&
      !lenderActivity.isPrivyRegistered &&
      !showPrivyRegisterModal
    ) {
      setPrivyRegisterModal(true);
    }
  }, [
    lenderActivity.isPrivyParameterActive,
    lenderActivity.isPrivyRegistered,
    lenderActivity.lenderStatus,
  ]);

  return (
    <div>
      <PrivyRegisterPopUp show={showPrivyRegisterModal} />
      <PrivyPopUp
        show={showPrivyModal}
        toggle={() => setPrivyModal(!showPrivyModal)}
        lenderActivity={lenderActivity}
        mobileNumber={mobileNumber}
      />
      <Navbar bg="light" className="py-md-3 py-2 shadow-0">
        <Container>
          <Link to="/beranda">
            <Navbar.Brand className="pointer">
              {windowDimensions.width < 600 ? (
                <img
                  src={iconPM}
                  className=""
                  width="40"
                  alt="Logo Pinjam Modal"
                />
              ) : (
                <img src={logoPM} className="" alt="Logo Pinjam Modal" />
              )}
            </Navbar.Brand>
          </Link>
          <Nav>
            <Nav.Item className="mx-3 mt-2">
              <NavLink
                className="nav-dash"
                activeClassName="active"
                to="/keranjang"
              >
                <FaShoppingCart className="" size="1.4em" />
                <span className="h6 tnav-dash"> Keranjang </span>
                {lenderActivity.totalActiveCart > 0 ? (
                  <Badge className="badge-top">
                    {lenderActivity.totalActiveCart}
                  </Badge>
                ) : null}
              </NavLink>
            </Nav.Item>
            <Nav.Item className="mx-3 mt-2">
              <NavLink
                className="nav-dash"
                activeClassName="active"
                to="/transaksi-pending"
              >
                <FaTasks className="" size="1.4em" />
                <span className="h6 tnav-dash"> Transaksi Pending</span>
                {lenderActivity.totalActiveTransaction > 0 ? (
                  <Badge className="badge-top">
                    {lenderActivity.totalActiveTransaction}
                  </Badge>
                ) : null}
              </NavLink>
            </Nav.Item>
            <Nav.Item className="mx-3 mt-2 align-items-center">
              <NavLink
                className="nav-dash"
                activeClassName="active"
                to="/checkout/list"
              >
                <FaReceipt className="" size="1.4em" />
                <span className="h6 tnav-dash"> Siap Didanai</span>
                {lenderActivity.totalActiveCheckout > 0 ? (
                  <Badge className="badge-top">
                    {lenderActivity.totalActiveCheckout}
                  </Badge>
                ) : null}
              </NavLink>
            </Nav.Item>
            <Nav.Item className="ml-2">
              <Dropdown alignRight>
                <Dropdown.Toggle
                  variant="link"
                  id="dropdown-basic"
                  className="text-dark ml-2"
                >
                  {util.jwtDecode(token).fullName
                    ? util.jwtDecode(token).fullName.split(" ")[0]
                    : null}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    href="/my-account"
                    className="py-2 my-0"
                    eventKey="1"
                  >
                    <FaRegUserCircle style={{ marginTop: "-2px" }} /> Akun Saya
                  </Dropdown.Item>
                  {/* <Dropdown.Divider />
                  <Dropdown.Item href="#" className="py-2 my-0" eventKey="2">
                    <FaRegBell style={{ marginTop: "-2px" }} /> Notifikasi
                  </Dropdown.Item> */}
                  <Dropdown.Divider />
                  <Dropdown.Item
                    href="/login"
                    className="py-2 my-0"
                    eventKey="3"
                    to="/"
                    onClick={() => localStorage.clear()}
                  >
                    <FaSignOutAlt style={{ marginTop: "-2px" }} /> Keluar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>

      <Navbar bg="light" expand="lg">
        <Container>
          <hr className="mt-0" />
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="w-100 py-2"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <div className="d-flex justify-content-between tblue">
              <h4 className="py-0 my-0">Menu Pendanaan</h4>
              <span>{showDropdown ? <FaCaretUp /> : <FaCaretDown />}</span>
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="py-1 d-flex justify-content-between w-100"
              activeKey="/dashboard"
            >
              <Nav.Item className="py-1">
                <NavLink
                  className="nav-link nav-dash"
                  to="/beranda"
                  activeClassName="active"
                >
                  <RiDashboardFill style={{ marginTop: "-5px" }} /> Beranda
                </NavLink>
              </Nav.Item>
              <Nav.Item className="py-1">
                <NavLink
                  className="nav-link nav-dash"
                  to="/daftar-pinjaman"
                  activeClassName="active"
                >
                  <RiFileList2Fill style={{ marginTop: "-5px" }} /> Daftar
                  Pinjaman
                </NavLink>
              </Nav.Item>
              <Nav.Item className="py-1">
                <NavLink
                  className="nav-link nav-dash"
                  to="/portfolio"
                  activeClassName="active"
                >
                  <RiFileHistoryFill style={{ marginTop: "-5px" }} /> Portofolio
                </NavLink>
              </Nav.Item>
              <Nav.Item className="py-1">
                <NavLink
                  className="nav-link nav-dash"
                  to="/mutasi"
                  activeClassName="active"
                >
                  <RiContactsFill style={{ marginTop: "-5px" }} /> Mutasi
                </NavLink>
              </Nav.Item>
              <Nav.Item className="py-1">
                <NavLink
                  className="nav-link nav-dash"
                  to="/informasi"
                  activeClassName="active"
                >
                  <RiInformationFill style={{ marginTop: "-5px" }} /> Informasi
                </NavLink>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default DashboardNavbar;
