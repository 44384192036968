import { ToastContainer } from "react-toastify";
import ModalUpdateVersion from "./components/ModalUpdateVersion";
import Router from "./routers/router";

function App() {
  if (process.env.NODE_ENV !== "production") {
    console.warn = () => {};
    console.error = () => {};
  }
  return (
    <div className="App">
      <Router />
      <ToastContainer />
      <ModalUpdateVersion />
    </div>
  );
}

export default App;
