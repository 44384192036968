import LenderApi from "../../api/lender-api";

export const getLenderActivity = () => async (dispatch) => {
  let lenderActivity = {
    isAutoSign: false,
    isChannelingActive: false,
    isPrivyRegistered: false,
    isSuperLender: false,
    totalActiveCart: 0,
    totalActiveCheckout: 0,
    totalActiveTransaction: 0,
    documentType: null,
    loanId: null,
    lenderStatus: null,
    isPrivyParameterActive: false,
  };
  const api = new LenderApi();
  const response = await api.getLenderActivity();
  if (response.message === "SUCCESS" && response.status === "SUCCESS") {
    lenderActivity = response.data;
  }
  dispatch(setStateLenderActivity(lenderActivity));
};

export const setStateLenderActivity = (payload) => ({
  type: "SET_STATE_LENDER_ACTIVITY",
  payload,
});
