import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";

export default class LenderApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getLenderActivity() {
    let result = OpResult.failed("Failed To Get Lender Activity");
    try {
      let resApi = await this.get("/lender/activity");
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error Get Lender Activity", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }
}
