import React from "react";
import DashboardNavbar from "./DashboardNavbar";
import MainFooter from "./MainFooter";

class DashboardLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <main>
        <DashboardNavbar />
        <div>{children}</div>
        <MainFooter />
      </main>
    );
  }
}

export default DashboardLayout;
