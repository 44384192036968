import { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container, Button, Overlay } from "react-bootstrap";
import logoPM from "../images/logo-pinjam-modal.svg";
import iconPM from "../images/logo-pinjam-modal-small.png";

// icons
import { GoInfo } from "react-icons/go";
import { useSelector } from "react-redux";
import { FaSignOutAlt } from "react-icons/fa";
import axios from "axios";

const MainNavbar = () => {
  // window resolutions
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  const { token, lenderStatus } = useSelector((state) => ({
    token: state.user.token,
    lenderStatus: state.user.lenderStatus,
  }));

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const handleResize = useCallback(() => {
    setWindowDimensions(getWindowDimensions());
  }, []);

  const mountEventResizeListener = useCallback(() => {
    window.addEventListener("resize", handleResize);
  }, [handleResize]);

  // npl
  const [npl, setNpl] = useState(0);
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const getNpl = useCallback(async () => {
    let result = 0;
    try {
      const response = await axios.post(
        "https://api.pinjammodal.io:8443/landing/overview"
      );
      const data = response.data.data;
      const npl = data.find((x) => x.name === "tkb_90")?.value;
      result = npl;
    } catch (error) {
      console.log(error);
    } finally {
      setNpl(result);
    }
  }, []);

  // component did update
  useEffect(() => {
    getNpl();
    mountEventResizeListener();

    // component will unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mountEventResizeListener, getNpl, handleResize]);

  return (
    <Navbar bg="light" expand="lg" className="py-md-3 py-2">
      <Container>
        <Link to="/">
          <Navbar.Brand className="pointer">
            {windowDimensions.width < 400 ? (
              <img
                src={iconPM}
                className=""
                width="40"
                alt="Logo Pinjam Modal"
              />
            ) : (
              <img src={logoPM} className="" alt="Logo Pinjam Modal" />
            )}
          </Navbar.Brand>
        </Link>
        <Navbar.Brand className="npl-phone">
          <div
            className="ml-md-3 mt-md-2 pointer d-block d-md-none mt-2 npl"
            ref={target}
            onClick={() => setShow(!show)}
          >
            <h6 className="mb-0">
              TKB90 <GoInfo />
            </h6>
            <h5 className="font-weight-bold" style={{ color: "#0e8642" }}>
              {npl}
            </h5>
            <Overlay target={target.current} show={show} placement="bottom">
              {({ placement, arrowProps, show: _show, popper, ...props }) => (
                <div
                  {...props}
                  style={{
                    backgroundColor: "rgba(36,136,66, 0.85)",
                    padding: "10px",
                    color: "white",
                    borderRadius: 3,
                    width: "300px",
                    height: "auto",
                    ...props.style,
                  }}
                >
                  <span className="h6">
                    TKB90 adalah ukuran tingkat keberhasilan penyelenggara
                    fintech-peer-to-peer (P2P) lending dalam menfasilitasi
                    penyelesaian kewajiban pinjam meminjam dalam jangka waktu
                    sampai dengan 90 hari terhitung sejak jatuh tempo.
                  </span>
                </div>
              )}
            </Overlay>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <a
              href="https://pinjammodal.id/"
              className="nav-item nav-link ml-0 ml-md-3"
            >
              Pinjaman
            </a>

            <Link to="/" className="nav-item nav-link ml-0 ml-md-3">
              Pendanaan
            </Link>

            <Link to="/tentang-kami" className="nav-item nav-link ml-0 ml-md-3">
              Tentang Kami
            </Link>

            <a
              href="https://blog.pinjammodal.id/"
              className="nav-item nav-link ml-0 ml-md-3"
              target="_blank"
              rel="noreferrer"
            >
              Blog
            </a>
          </Nav>
          <Nav className="ml-auto">
            {token ? (
              <div className="nav-item nav-link">
                <Button
                  hidden={lenderStatus !== "ACTIVE"}
                  onClick={() => {
                    window.location = "/beranda";
                  }}
                >
                  Masuk
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => {
                    localStorage.clear();
                    window.location = "/login";
                  }}
                  className="tred"
                >
                  <FaSignOutAlt className="mr-2" />
                  Keluar
                </Button>
              </div>
            ) : (
              <Link to="/login" className="nav-item nav-link">
                <Button>Masuk/Daftar</Button>
              </Link>
            )}

            <div
              className="ml-md-3 mt-md-2 pointer d-none d-sm-block"
              ref={target}
              onClick={() => setShow(!show)}
            >
              <h6 className="mb-0">
                TKB90 <GoInfo />
              </h6>
              <h5 className="font-weight-bold" style={{ color: "#0e8642" }}>
                {npl}
              </h5>
              <Overlay target={target.current} show={show} placement="bottom">
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                  <div
                    {...props}
                    style={{
                      backgroundColor: "rgba(36,136,66, 0.85)",
                      padding: "10px",
                      color: "white",
                      borderRadius: 3,
                      width: "300px",
                      height: "auto",
                      ...props.style,
                    }}
                  >
                    <span className="h6">
                      TKB90 adalah ukuran tingkat keberhasilan penyelenggara
                      fintech-peer-to-peer (P2P) lending dalam menfasilitasi
                      penyelesaian kewajiban pinjam meminjam dalam jangka waktu
                      sampai dengan 90 hari terhitung sejak jatuh tempo.
                    </span>
                  </div>
                )}
              </Overlay>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavbar;
