import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import AuthenticatedLayoutRoute from "../components/AuthenticatedLayoutRoute";
import DashboardLayout from "../components/DashboardLayout";
import LayoutRoute from "../components/LayoutRoute";
import MainLayout from "../components/MainLayout";
import { storage } from "../redux/local_storage";
import ScrollTop from "./scrollTop";

// logo
import logoPM from "../images/logo-pinjam-modal.svg";

// meta tag
import MetaTagDecorator from "../helper/meta_decorator";

// pages
const Homepage = lazy(() => import("../pages/homepage"));

const Auth = lazy(() => import("../pages/auth"));
const EmailSuccess = lazy(() => import("../pages/registration/email-success"));
const EmailVerification = lazy(() =>
  import("../pages/registration/email-verification")
);
const LenderVerification = lazy(() =>
  import("../pages/registration/lender-verification")
);
const LenderRejected = lazy(() =>
  import("../pages/registration/lender-rejected")
);
const ForgotPassword = lazy(() => import("../pages/auth/forgot-password"));
const ChangePassword = lazy(() => import("../pages/auth/change-password"));
const Registration = lazy(() => import("../pages/registration"));

const Dashboard = lazy(() => import("../pages/dashboard"));
const Keranjang = lazy(() => import("../pages/cart"));
const Sign = lazy(() => import("../pages/cart/sign"));
const PendingTransactions = lazy(() => import("../pages/pending-transactions"));
const PendingDetail = lazy(() =>
  import("../pages/pending-transactions/detail")
);
const PaymentMethod = lazy(() => import("../pages/payment-methods"));
const VaPayment = lazy(() => import("../pages/payment-methods/va-payment"));
const LoanList = lazy(() => import("../pages/loan-list"));
const Portfolio = lazy(() => import("../pages/portfolio"));
const OTP = lazy(() => import("../pages/registration/otp"));
const Mutasi = lazy(() => import("../pages/mutasi"));
const MyAccount = lazy(() => import("../pages/account/MyAccount"));
const InformasiRDL = lazy(() => import("../pages/information"));
const CheckoutListPage = lazy(() => import("../pages/checkout"));
const RDLPayment = lazy(() => import("../pages/payment-methods/rdl-payment"));

// footer
const About = lazy(() => import("../pages/Footerpage/About"));
const Directors = lazy(() => import("../pages/Footerpage/Directors"));
const Term = lazy(() => import("../pages/Footerpage/Term"));
const Policy = lazy(() => import("../pages/Footerpage/Policy"));
const HowtobeBorrower = lazy(() =>
  import("../pages/Footerpage/HowtobeBorrower")
);
const HowtobeLender = lazy(() => import("../pages/Footerpage/HowtobeLender"));
const Cost = lazy(() => import("../pages/Footerpage/Cost"));
const FAQ = lazy(() => import("../pages/Footerpage/Faq"));
const ComplaintService = lazy(() =>
  import("../pages/Footerpage/ComplaintService")
);
const FinancialReport = lazy(() =>
  import("../pages/Footerpage/FinancialReport")
);
const BorrowerRisk = lazy(() => import("../pages/Footerpage/BorrowerRisk"));
const LenderRisk = lazy(() => import("../pages/Footerpage/LenderRisk"));

const NotFoundPage = lazy(() => import("../pages/not-found-page"));

export default function App() {
  const checkAuthentication = () => {
    const store = storage.get(process.env.REACT_APP_LOCAL_STORAGE_NAME);
    if (store) return true;
    return false;
  };

  const checkAuthorization = () => {
    const store = storage.get(process.env.REACT_APP_LOCAL_STORAGE_NAME);
    if (store) {
      if (store.lenderStatus === "ACTIVE") {
        return true;
      }
    }
    return false;
  };

  return (
    <Router>
      <MetaTagDecorator
        title="Pinjam Modal | Pendanaan"
        description="Pinjam modal menawarkan solusi modal usaha untuk UMKM online dengan cara mempertemukan peminjam dan pendana lewat platform aplikasi pinjaman online; Pinjam Modal."
      />
      <Suspense
        fallback={
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img src={logoPM} alt="" />
            <div className="pt-3 d-flex justify-content-center">
              <BeatLoader color="#007edc" size="12px" />
            </div>
          </div>
        }
      >
        <ScrollTop>
          <Switch>
            <LayoutRoute
              exact
              path="/"
              layout={MainLayout}
              component={Homepage}
            />
            <LayoutRoute
              exact
              path="/login"
              layout={MainLayout}
              component={Auth}
            />
            <LayoutRoute
              exact
              path="/lupa-password"
              layout={MainLayout}
              component={ForgotPassword}
            />
            <LayoutRoute
              exact
              path="/ubah-password/:resetPasswordString"
              layout={MainLayout}
              component={ChangePassword}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/registrasi"
              layout={MainLayout}
              component={Registration}
              authentication={checkAuthentication()}
              authorization
            />
            <AuthenticatedLayoutRoute
              exact
              path="/beranda"
              layout={DashboardLayout}
              component={Dashboard}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/keranjang"
              layout={DashboardLayout}
              component={Keranjang}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/tanda-tangan-digital"
              layout={DashboardLayout}
              component={Sign}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/transaksi-pending"
              layout={DashboardLayout}
              component={PendingTransactions}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/pending-detail/:id"
              layout={DashboardLayout}
              component={PendingDetail}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/pilih-pembayaran/:id"
              layout={DashboardLayout}
              component={PaymentMethod}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/checkout/detail/cimb-niaga/:id"
              layout={DashboardLayout}
              component={VaPayment}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/checkout/list"
              layout={DashboardLayout}
              component={CheckoutListPage}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/daftar-pinjaman"
              layout={DashboardLayout}
              component={LoanList}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/portfolio"
              layout={DashboardLayout}
              component={Portfolio}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/otp"
              layout={MainLayout}
              component={OTP}
              authentication={checkAuthentication()}
              authorization
            />
            <AuthenticatedLayoutRoute
              exact
              path="/mutasi"
              layout={DashboardLayout}
              component={Mutasi}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <LayoutRoute
              exact
              path="/email-sukses"
              layout={MainLayout}
              component={EmailSuccess}
            />
            <LayoutRoute
              exact
              path="/email-validasi"
              layout={MainLayout}
              component={EmailVerification}
            />
            <LayoutRoute
              exact
              path="/menunggu-persetujuan"
              layout={MainLayout}
              component={LenderVerification}
            />
            <LayoutRoute
              exact
              path="/pengajuan-ditolak"
              layout={MainLayout}
              component={LenderRejected}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/my-account"
              layout={DashboardLayout}
              component={MyAccount}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/informasi"
              layout={DashboardLayout}
              component={InformasiRDL}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            <AuthenticatedLayoutRoute
              exact
              path="/checkout/detail/rdl-bni/:id"
              layout={DashboardLayout}
              component={RDLPayment}
              authentication={checkAuthentication()}
              authorization={checkAuthorization()}
            />
            {/* footer */}
            <LayoutRoute
              exact
              path="/tentang-kami"
              layout={MainLayout}
              component={About}
            />
            <LayoutRoute
              exact
              path="/struktur-manajemen"
              layout={MainLayout}
              component={Directors}
            />
            <LayoutRoute
              exact
              path="/syarat-ketentuan"
              layout={MainLayout}
              component={Term}
            />
            <LayoutRoute
              exact
              path="/kebijakan-privasi"
              layout={MainLayout}
              component={Policy}
            />
            <LayoutRoute
              exact
              path="/cara-meminjam"
              layout={MainLayout}
              component={HowtobeBorrower}
            />
            <LayoutRoute
              exact
              path="/cara-mendanai"
              layout={MainLayout}
              component={HowtobeLender}
            />
            <LayoutRoute
              exact
              path="/biaya"
              layout={MainLayout}
              component={Cost}
            />
            <LayoutRoute
              exact
              path="/pertanyaan-umum"
              layout={MainLayout}
              component={FAQ}
            />
            <LayoutRoute
              exact
              path="/layanan-pengaduan"
              layout={MainLayout}
              component={ComplaintService}
            />
            <LayoutRoute
              exact
              path="/publikasi-laporan-keuangan"
              layout={MainLayout}
              component={FinancialReport}
            />
            <LayoutRoute
              exact
              path="/resiko-penerima-pinjaman"
              layout={MainLayout}
              component={BorrowerRisk}
            />
            <LayoutRoute
              exact
              path="/resiko-pemberi-pinjaman"
              layout={MainLayout}
              component={LenderRisk}
            />
            <LayoutRoute
              exact
              path="*"
              layout={MainLayout}
              component={NotFoundPage}
            />
          </Switch>
        </ScrollTop>
      </Suspense>
    </Router>
  );
}
