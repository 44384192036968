import { combineReducers } from "redux";
import { user } from "./user_reducer";
import { profile } from "./profile_reducer";
import { registration } from "./registration_reducer";
import { master } from "./master_reducer";
import { cart } from "./cart_reducer";
import { lenderActivity } from "./lender_activity_reducer";

export default combineReducers({
  user,
  profile,
  registration,
  master,
  cart,
  lenderActivity,
});
