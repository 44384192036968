import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";

export default class PrivyApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getPrivyDocument(payload) {
    let result = OpResult.failed("Failed To Get Privy Document");
    try {
      // set headers so the response is buffer
      this.config = {
        responseType: "arraybuffer",
        headers: {
          ...this.config.headers,
        },
      };

      // post api
      let resApi = await this.downloadWithPost(
        "/privy/document/download",
        payload,
        "Dokumen"
        // this.config
      );

      // check the response
      if (resApi) {
        const arrayBuffer = new Uint8Array(resApi);
        result = new Blob([arrayBuffer], { type: "application/pdf" });
      }
    } catch (e) {
      console.log("Error Get Privy Document", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getPrivyDocumentDraft(payload) {
    let result = OpResult.failed("Failed To Get Privy Document");
    try {
      // set headers so the response is buffer
      this.config = {
        responseType: "arraybuffer",
        headers: {
          ...this.config.headers,
        },
      };

      // post api
      let resApi = await this.downloadWithPost(
        "/privy/document/download/draft",
        payload,
        "Dokumen perjanjian "
      );

      // check the response
      if (resApi) {
        const arrayBuffer = new Uint8Array(resApi);
        result = new Blob([arrayBuffer], { type: "application/pdf" });
      }
    } catch (e) {
      console.log("Error Get Privy Document", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async uploadPrivyDocument(payload) {
    let result = OpResult.failed("Failed To Upload Privy Document");
    try {
      let resApi = await this.post(`/privy/document/upload`, payload);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error Upload Privy Document", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async requestPrivyOTP(payload) {
    let result = OpResult.failed("Failed To Request Privy OTP");
    try {
      let resApi = await this.post(`/privy/document/otp/send`, payload);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error Upload Request Privy OTP", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async verifyPrivyOTP(payload) {
    let result = OpResult.failed("Failed To Verify Privy OTP");
    try {
      let resApi = await this.post(`/privy/document/otp/verify`, payload);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error Upload Verify Privy OTP", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }
}
