import React from "react";
import MainNavbar from "./MainNavbar";
import MainFooter from "./MainFooter";

class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <main>
        <MainNavbar />
        <div>{children}</div>
        <MainFooter />
      </main>
    );
  }
}

export default MainLayout;
