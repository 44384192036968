import React from "react";
import { Modal, Button } from "react-bootstrap";
import PriviLogo from "../images/logos/logo-privy.png";

const PrivyRegisterPopUp = ({ show }) => {
  return (
    <Modal show={show}>
      <Modal.Header>Perhatian</Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          <h5>
            Akun anda belum registrasi{" "}
            <img src={PriviLogo} alt="privy.id" width="80px" /> untuk tanda
            tangan digital.
          </h5>
        </div>
        <p>Silakan hubungi lender@fit.id untuk mendaftarkannya. Terima Kasih</p>
        <div className="text-center">
          <Button
            variant="danger"
            onClick={() => {
              localStorage.clear();
              window.location = "/login";
            }}
          >
            Keluar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PrivyRegisterPopUp;
