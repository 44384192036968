import UserApi from "../../api/user-api";
import util from "../../helper/util";

export const getLenderProfile = () => async (dispatch) => {
  const api = new UserApi();
  dispatch(
    setStateProfile({
      lenderType: null,
      email: null,
      fullName: null,
      genderId: null,
      mobileNumber: null,
      placeOfBirth: null,
      dateOfBirth: null,
      maritalStatusId: null,
      identificationNumber: null,
      npwpNumber: null,
      lenderAddress: null,
      lenderProvince: null,
      lenderCity: null,
      lenderDistrict: null,
      lenderSubDistrict: null,
      cardHolderName: null,
      bankAccountNumber: null,
      industryTypeId: null,
      businessEntityTypeId: null,
      salary: null,
      companyName: null,
      noAktaPendirian: null,
      tanggalBerdiri: null,
      lenderStatus: null,
      emailValidation: null,
      religionId: null,
      lenderZip: null,
      bankId: null,
      lendingAwareness: {
        sosial_media: false,
        iklan: false,
        teman_kerabat: false,
        media: false,
        lainnya: false,
      },
      lendingAwarenessOthers: null,
      investmentProduct: {
        reksadana: false,
        deposito: false,
        usaha: false,
        lainnya: false,
      },
      investmentProductOthers: null,
    })
  );
  const response = await api.getLenderProfile();
  if (response.status === "SUCCESS" && response.message === "SUCCESS") {
    let investmentProduct = util.jsonParse(response.data.productInvestment);
    let investmentProductOthers = null;
    let lendingAwareness = util.jsonParse(response.data.lendingAwareness);
    let lendingAwarenessOthers = null;
    if (investmentProduct) {
      if (typeof investmentProduct.lainnya === "string") {
        investmentProductOthers = investmentProduct.lainnya;
      }
    } else {
      investmentProduct = {
        reksadana: false,
        deposito: false,
        usaha: false,
        lainnya: false,
      };
    }
    if (lendingAwareness) {
      if (lendingAwareness.lainnya) {
        lendingAwarenessOthers = response.data.lendingAwarenessLain;
      }
    } else {
      lendingAwareness = {
        sosial_media: false,
        iklan: false,
        teman_kerabat: false,
        media: false,
        lainnya: false,
      };
    }
    dispatch(
      setStateProfile({
        lenderType: response.data.lenderType,
        email: response.data.email,
        fullName: response.data.fullName,
        bankAccountNumber: response.data.bankAccountNumber,
        bankId: response.data.bankId,
        businessEntityTypeId: response.data.businessEntityTypeId,
        cardHolderName: response.data.cardHolderName,
        companyName: response.data.companyName,
        dateOfBirth: response.data.dateOfBirth,
        emailValidation: response.data.emailValidation,
        genderId: response.data.genderId,
        identificationNumber: response.data.identificationNumber,
        industryTypeId: response.data.industryTypeId,
        lenderAddress: response.data.lenderAddress,
        lenderCity: response.data.lenderCity,
        lenderDistrict: response.data.lenderDistrict,
        lenderProvince: response.data.lenderProvince,
        lenderStatus: response.data.lenderStatus,
        lenderSubDistrict: response.data.lenderSubDistrict,
        lenderZip: response.data.lenderZip,
        lendingAwareness,
        lendingAwarenessOthers,
        maritalStatusId: response.data.maritalStatusId,
        mobileNumber: response.data.mobileNumber,
        noAktaPendirian: response.data.noAktaPendirian,
        npwpNumber: response.data.npwpNumber,
        placeOfBirth: response.data.placeOfBirth,
        investmentProduct,
        investmentProductOthers,
        religionId: response.data.religionId,
        salary: response.data.salary,
        tanggalBerdiri: response.data.tanggalBerdiri,
      })
    );
  }
};

export const setStateProfile = (objValue) => ({
  type: "SET_STATE_PROFILE",
  payload: objValue,
});
