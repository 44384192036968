/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Countdown from "react-countdown";
import { FcTwoSmartphones } from "react-icons/fc";
import { PulseLoader } from "react-spinners";
import { toast } from "react-toastify";
import PrivyApi from "../api/privy-api";

const PrivyPopUp = ({ show, toggle, lenderActivity, mobileNumber }) => {
  const [state, setState] = useState({
    startCountDown: false,
    count: 0,
    otpCode: null,
    isLoading: false,
    timeCountDown: 0,
  });

  const changeState = (objValue) => {
    setState((prevState) => ({ ...prevState, ...objValue }));
  };

  const sendOTP = async () => {
    const api = new PrivyApi();
    const response = await api.requestPrivyOTP({
      documentType: lenderActivity.documentType,
      loanId: lenderActivity.loanId,
    });
    if (response.status === "SUCCESS" && response.message === "SUCCESS") {
      toast.success("Kode OTP sukses dikirim.", {
        position: "top-center",
        hideProgressBar: true,
      });
      changeState({
        count: state.count + 1,
        startCountDown: true,
        timeCountDown: 60000,
      });
    } else {
      let message = "Request OTP gagal. Hubungi lender@fit.id";
      switch (response.message) {
        case "ACCESS_TOKEN_INVALID":
          message =
            "Request OTP gagal. Akses Token Kadaluarsa Hubungi lender@fit.id";
          break;
        case "MAXIMUM_OTP_REQUEST_EXCEDEED":
          message =
            "Batas maksimal request OTP telah habis. Hubungi lender@fit.id";
          break;
        default:
          break;
      }
      toast.error(message, {
        position: "top-center",
        hideProgressBar: true,
        autoClose: false,
      });
    }
  };

  const verifyOTP = async () => {
    changeState({ isLoading: true });
    const api = new PrivyApi();
    const response = await api.verifyPrivyOTP({
      otp: state.otpCode,
      documentType: lenderActivity.documentType,
      loanId: lenderActivity.loanId,
    });
    if (response.status === "SUCCESS" && response.message === "SUCCESS") {
      toast.success("Verifikasi OTP Berhasil.", {
        position: "top-center",
        hideProgressBar: false,
      });
      setTimeout(() => window.location.reload(), 5000);
    } else {
      let message = "Verifikasi OTP gagal. Hubungi lender@fit.id";
      switch (response.message) {
        case "ACCESS_TOKEN_INVALID":
          message =
            "Verifikasi OTP gagal. Akses Token Kadaluarsa Hubungi lender@fit.id";
          break;
        case "OTP_CODE_INVALID":
          message = "Kode OTP yang Anda Masukan Salah.";
          break;
        default:
          break;
      }
      toast.error(message, {
        position: "top-center",
        hideProgressBar: true,
        autoClose: false,
      });
    }
    changeState({ isLoading: false });
  };

  return (
    <Modal show={show} toggle={toggle} onHide={toggle}>
      <Modal.Header className="ml-header">
        Tanda Tangan Digital Perjanjian Pinjaman
      </Modal.Header>
      <Modal.Body className="py-3">
        <div className="my-3">
          <div className="text-center">
            <FcTwoSmartphones size="80px" />
            <h3 className="font-weight-bold pt-3">Kode Verifikasi</h3>
          </div>
          <Row>
            <Col md={12} className="text-center px-5">
              <p style={{ fontSize: "14px" }}>
                Ada {lenderActivity.totalUnsignDocument} dokumen yang butuh
                ditandatangani.
              </p>
              <Form
                className="mt-4"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (state.otpCode && String(state.otpCode).length === 5) {
                    verifyOTP();
                  }
                }}
              >
                <Form.Group className="mb-3">
                  <Form.Control
                    placeholder="_ _ _ _ _"
                    value={state.otpCode}
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                    maxLength={5}
                    onChange={(e) =>
                      changeState({
                        otpCode: e.target.value.replace(/[^0-9]/g, ""),
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    OTP Salah
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
              {state.startCountDown ? (
                <Countdown
                  date={Date.now() + state.timeCountDown}
                  onComplete={() => changeState({ startCountDown: false })}
                  onTick={(item) => changeState({ timeCountDown: item.total })}
                  renderer={(item) => (
                    <h6 className="text-muted">
                      Mohon tunggu dalam {item.seconds} Detik untuk mengirim
                      ulang
                    </h6>
                  )}
                />
              ) : (
                <div>
                  {state.count === 0 ? (
                    <h6>
                      <a href="#" onClick={() => sendOTP()}>
                        Klik disini
                      </a>{" "}
                      untuk mengirimkan kode OTP ke nomor HP.{" "}
                      <b>{mobileNumber}</b>
                    </h6>
                  ) : (
                    <h6>
                      Tidak menerima kode?{" "}
                      <a href="#" onClick={() => sendOTP()}>
                        Kirim ulang
                      </a>
                    </h6>
                  )}
                </div>
              )}
              <Button
                variant="primary"
                block
                className="mt-4 py-2"
                onClick={() => verifyOTP()}
                disabled={
                  !state.otpCode ||
                  String(state.otpCode).length !== 5 ||
                  state.isLoading
                }
              >
                {state.isLoading ? (
                  <PulseLoader color="#fff" size="10px" />
                ) : (
                  "VERIFIKASI"
                )}
              </Button>
              <div className="text-center mt-3">
                <Button
                  block
                  variant="link"
                  onClick={() => {
                    localStorage.clear();
                    window.location = "/login";
                  }}
                >
                  Keluar
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PrivyPopUp;
