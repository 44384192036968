const initState = {
  isAutoSign: false,
  isChannelingActive: false,
  isPrivyRegistered: false,
  isSuperLender: false,
  totalActiveCart: 0,
  totalActiveCheckout: 0,
  totalActiveTransaction: 0,
  documentType: null,
  loanId: null,
  lenderStatus: null,
  isPrivyParameterActive: false,
};

export const lenderActivity = (state = initState, action) => {
  switch (action.type) {
    case "SET_STATE_LENDER_ACTIVITY":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
