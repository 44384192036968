const initState = {
  genderList: [],
  industryTypeList: [],
  businessEntityTypeList: [],
  bankList: [],
  religionList: [],
  maritalStatusList: [],
  provinceList: [],
  cityList: [],
  districtList: [],
  subDistrictList: [],
  zipCodeList: [],
};

export const master = (state = initState, action) => {
  switch (action.type) {
    case "SET_STATE_MASTER":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
