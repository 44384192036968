import axios from "axios";
import { storage } from "../redux/local_storage";
import { OpResult } from "../helper/operation_result";
import util from "../helper/util";
import { confirmAlert } from "react-confirm-alert";
import { Button } from "react-bootstrap";
import CryptoJS from "crypto-js";

const encryptKeyData = ({ url, payloadBody = {}, userId }) => {
  let encryptedSctring = undefined;
  try {
    if (userId) {
      const stringToEncrypt = `${url}:${JSON.stringify(payloadBody)}`;
      console.log(stringToEncrypt);
      encryptedSctring = CryptoJS.HmacSHA256(
        stringToEncrypt,
        String(userId)
      ).toString();
    }
  } catch (error) {
    console.log(error);
  } finally {
    return encryptedSctring;
  }
};

class BaseApiRequest {
  constructor(endpoint) {
    this.endpoint = endpoint;
    this.token = undefined;
    this.config = undefined;
    //init token
    this.init();
  }

  init() {
    const store = storage.get(process.env.REACT_APP_LOCAL_STORAGE_NAME);
    if (store) {
      if (store.token) {
        this.setConfig(store.token);
      }
    }
  }

  setConfig(token) {
    this.token = token;
    const hashedToken = util.jwtHashed(token);
    this.config = {
      headers: { authorization: hashedToken },
      maxContentLength: 100000000,
      maxBodyLength: 1000000000,
      validateStatus: function () {
        return true;
      },
    };
  }

  async resetLocalStorageAndRedirectToLoginPage() {
    storage.delete(process.env.REACT_APP_LOCAL_STORAGE_NAME);
    confirmAlert({
      customUI: () => {
        return (
          <div className="custom-ui">
            <h3>Perhatian</h3>
            <h5>{"Sesi Habis, Silahkan masuk kembali!"}</h5>
            <div className="text-center mt-3">
              <Button
                block
                variant="link"
                onClick={() => {
                  localStorage.clear();
                  window.location = "/login";
                }}
              >
                Keluar
              </Button>
            </div>
          </div>
        );
      },
      onClickOutside: () => (window.location.href = "/login"),
    });
  }

  async get(url) {
    try {
      this.init();
      const userId = util.jwtDecode(this.token)?.userId;
      const keySecret = encryptKeyData({ url, payloadBody: {}, userId });
      const headers = this.config?.headers;

      if (keySecret) {
        headers["key-secret"] = keySecret;
      }

      let response = await axios.get(this.endpoint + url, {
        ...this.config,
        headers,
      });

      if (response.status >= 400 && response.status < 500) {
        if (response.status === 401) {
          this.resetLocalStorageAndRedirectToLoginPage();
        }
        let resp = response.data
          ? response.data
          : OpResult.failed(response.statusText);
        resp.code = response.status;
        return resp;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log("[API_GET]", error.response);
      return OpResult.failed(`request failed`);
    }
  }

  async post(url, req_body) {
    try {
      this.init();
      axios.defaults.validateStatus = function () {
        return true;
      };
      const userId = util.jwtDecode(this.token)?.userId;
      const keySecret = encryptKeyData({ url, payloadBody: req_body, userId });
      const headers = this.config?.headers;

      if (keySecret) {
        headers["key-secret"] = keySecret;
      }

      let response = await axios.post(this.endpoint + url, req_body, {
        ...this.config,
        headers,
      });

      if (response.status >= 400 && response.status < 500) {
        if (response.status === 401) {
          this.resetLocalStorageAndRedirectToLoginPage();
        }
        let resp = response.data
          ? response.data
          : OpResult.failed(response.statusText);
        resp.code = response.status;
        return resp;
      } else {
        return response.data;
      }
    } catch (error) {
      console.log("[API_POST] ", error.response);
      return OpResult.failed(`request failed`);
    }
  }

  async downloadWithPost(url, payload, document_name) {
    try {
      const config = { ...this.config, responseType: "blob" };

      const userId = util.jwtDecode(this.token)?.userId;
      const keySecret = encryptKeyData({ url, payloadBody: payload, userId });
      const headers = this.config?.headers;

      if (keySecret) {
        headers["key-secret"] = keySecret;
      }
      if (this.token) {
        let response = await axios.post(this.endpoint + url, payload, config);
        if (response.status >= 400 && response.status < 500) {
          if (response.status === 401) {
            this.resetLocalStorageAndRedirect();
          }
          let resp = response.data
            ? response.data
            : OpResult.failed(response.statusText);
          resp.code = response.status;
          return resp;
        } else {
          const contentType = response.headers["content-type"];
          const contentTypesToExtensions = {
            "application/pdf": ".pdf",
            "image/jpeg": ".jpg",
            "image/png": ".png",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              ".xlsx",
          };
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            document_name + contentTypesToExtensions[contentType]
          );
          document.body.appendChild(link);
          link.click();
          return true;
        }
      } else {
        this.resetLocalStorageAndRedirect();
      }
    } catch (error) {
      console.log("[API_DOWNLOAD_POST] ", error);
      return OpResult.failed(`request failed`);
    }
  }
}

export { BaseApiRequest };
