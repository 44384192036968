import { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Collapse } from "react-bootstrap";
import logoWhite from "../images/logo-white.svg";
import logoOjk from "../images/logos/logo-ojk.svg";
import logoKominfo from "../images/logos/logo-kominfo.svg";
import logoIso from "../images/logos/iso-white.png";

// Icons
import { FiMail } from "react-icons/fi";

const MainFooter = () => {
  const e = new Date();
  const [open, setOpen] = useState(false);
  return (
    <footer>
      <div className="bg-stone py-5">
        <Container>
          <Row>
            <Col md={6} lg={3} className="mb-4 mb-lg-0">
              <img alt="" src={logoWhite} />
              <h6 className="mt-2 text-white">
                PT. FINANSIAL INTEGRASI TEKNOLOGI
              </h6>
              <h4 className="text-white mt-4">Kantor Pusat</h4>
              <p style={{ color: "#b1b1b1" }}>
                Foresta Business Loft 5 No 11 BSD City, Banten 15331
              </p>
              <h4 className="text-white">Layanan Konsumen</h4>
              <p style={{ color: "#b1b1b1" }}>
                Hubungi Kami di hari Senin - Jumat jam 08.00 - 16.00 WIB
              </p>
              <div className="d-flex text-white">
                <FiMail className="icon-footer" size={20} />
                <a
                  href="mailto:customer@pinjammodal.id"
                  className="ml-3 text-white"
                  style={{ letterSpacing: "0.3px" }}
                >
                  customer@pinjammodal.id
                </a>
              </div>
            </Col>
            <Col md={6} lg={3} className="mt-4 mt-md-0">
              <h4 className="text-white">Pinjam Modal</h4>
              <ul>
                <li>
                  <Link to="/tentang-kami">Tentang Kami</Link>
                </li>
                <li>
                  <Link to="/publikasi-laporan-keuangan">
                    Publikasi Laporan Keuangan
                  </Link>
                </li>

                <li>
                  <Link to="/struktur-manajemen">Struktur Manajemen</Link>
                </li>
                <li>
                  <a href="https://blog.pinjammodal.id/">Blog</a>
                </li>
                <li>
                  <a href="https://blog.pinjammodal.id/category/event-pinjam-modal/">
                    Event
                  </a>
                </li>
                <li>
                  <a href="https://blog.pinjammodal.id/category/sosialisasi/">
                    Sosialisasi
                  </a>
                </li>
                <li>
                  <a href="https://blog.pinjammodal.id/events/">
                    Kalender Kegiatan
                  </a>
                </li>
                <li>
                  <Link to="/syarat-ketentuan">Syarat dan ketentuan</Link>
                </li>
              </ul>
            </Col>
            <Col md={6} lg={3}>
              <h4 className="text-white">Bantuan</h4>
              <ul>
                <li>
                  <Link to="/resiko-penerima-pinjaman">
                    Resiko Penerima Pinjaman
                  </Link>
                </li>
                <li>
                  <Link to="/resiko-pemberi-pinjaman">
                    Resiko Pemberi Pinjaman
                  </Link>
                </li>
                <li>
                  <Link to="/cara-meminjam">Cara Meminjam</Link>
                </li>
                <li>
                  <Link to="/cara-mendanai">Cara Mendanai</Link>
                </li>
                <li>
                  <Link to="/biaya">Biaya</Link>
                </li>
                <li>
                  <Link to="/pertanyaan-umum">Pertanyaan Umum</Link>
                </li>
                <li>
                  <Link to="/layanan-pengaduan">Layanan Pengaduan</Link>
                </li>
                <li>
                  <Link to="/kebijakan-privasi">Kebijakan Privasi</Link>
                </li>
              </ul>
            </Col>
            <Col md={6} lg={3} className="mt-4 mt-md-0">
              <div>
                <img src={logoOjk} alt="" />
                <br />
                <a
                  href="https://www.ojk.go.id/id/kanal/iknb/financial-technology/Pages/Penyelenggara-Fintech-Terdaftar-dan-Berizin-di-OJK-per-11-Juni-2020.aspx"
                  style={{ fontSize: "14px" }}
                >
                  KEP - 20/D.05/2020
                </a>
              </div>
              <div className="mt-4">
                <img src={logoKominfo} alt="" />
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://drive.google.com/file/d/1hdcieXWEdB4gJbEkZw3kZLIdTMdX9kU9/view?usp=sharing"
                  style={{ fontSize: "14px" }}
                >
                  000506.01/DJAI.PSE/04/2021
                </a>
              </div>
              <div className="mt-4">
                <img style={{ height: "150px" }} alt="" src={logoIso} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="py-4">
        <Container>
          <Row>
            <Col md={12}>
              <h4>Perhatian</h4>
              <ol className="disclaimer_list">
                <li>
                  Layanan Pinjam Meminjam Berbasis Teknologi Informasi merupakan
                  kesepakatan perdata antara Pemberi Pinjaman dengan Penerima
                  Pinjaman, sehingga segala risiko yang timbul dari kesepakatan
                  tersebut ditanggung sepenuhnya oleh masing-masing pihak.
                </li>
                <li>
                  Resiko Pemberian Pinjaman atau Gagal Bayar dan seluruh
                  kerugian dari atau terkait dengan kesepakatan pinjam meminjam
                  ditanggung sepenuhnya oleh Pemberi Pinjaman. Tidak ada lembaga
                  atau otoritas negara yang bertanggung jawab atas resiko gagal
                  bayar dan kerugian tersebut.
                </li>
                <li>
                  Dengan menggunakan Layanan ini, maka Pengguna (Pemberi
                  Pinjaman dan/atau Penerima Pinjaman) menyatakan telah membaca
                  dan mempelajari setiap syarat dan ketentuan yang berlaku.
                </li>
                <Collapse in={open}>
                  <div id="collapse-item">
                    <li>
                      Penyelenggara dengan persetujuan dari masing-masing
                      Pengguna (Pemberi Pinjaman dan/atau Penerima Pinjaman)
                      mengakses, memperoleh, menyimpan, mengelola dan/atau
                      menggunakan data pribadi Pengguna ('Pemanfaatan Data')
                      pada atau di dalam benda, perangkat elektronik (termasuk
                      smartphone atau telepon seluler), perangkat keras
                      (hardware) maupun lunak (software), dokumen elektronik,
                      aplikasi atau sistem elektronik milik Pengguna atau yang
                      dikuasai Pengguna, dengan memberitahukan tujuan, batasan
                      dan mekanisme Pemanfaatan Data tersebut kepada Pengguna
                      yang bersangkutan sebelum memperoleh persetujuan yang
                      dimaksud.
                    </li>
                    <li>
                      Pemberi Pinjaman yang belum memiliki pengetahuan dan
                      pengalaman pinjam meminjam, disarankan untuk tidak
                      menggunakan layanan ini.
                    </li>
                    <li>
                      Penerima Pinjaman harus mempertimbangkan tingkat bunga
                      pinjaman dan biaya lainnya sesuai dengan kemampuan dalam
                      melunasi pinjaman.
                    </li>
                    <li>
                      Setiap perbuatan ilegal akan tercatat secara digital di
                      dunia maya sehingga dapat diketahui masyarakat luas di
                      media sosial, serta akan dilaporkan sepenuhnya kepada
                      pihak berwenang namun tidak terbatas pada Otoritas Jasa
                      Keuangan, sesuai dengan peraturan perundang – undangan
                      yang berlaku.
                    </li>
                    <li>
                      Pengguna harus membaca dan memahami informasi ini sebelum
                      membuat keputusan menjadi Pemberi Pinjaman atau Penerima
                      Pinjaman.
                    </li>
                    <li>
                      Pemerintah yaitu dalam hal ini Otoritas Jasa
                      Keuangan,tidak bertanggung jawab atas setiap pelanggaran
                      atau ketidakpatuhan oleh Pengguna,baik Pemberi Pinjaman
                      maupun Penerima Pinjaman(baik karena kesengajaan atau
                      kelalaian Pengguna) terhadap ketentuan peraturan
                      perundang-undangan maupun kesepakatan atau perikatan
                      antara Penyelenggara dengan Pemberi Pinjaman dan/atau
                      Penerima Pinjaman.
                    </li>
                    <li>
                      Setiap transaksi dan kegiatan pinjam meminjam akan
                      dilaporkan secara berkala kepada Otoritas Jasa keuangan
                      dan/atau Asosiasi Fintech Pendanaan Bersama Indonesia
                      untuk kepentingan Pusat Data Fintech Lending yang akan
                      dimanfaatkan bersama dengan para Penyelenggara, para
                      pelaku industri perbankan nasional dan industri keuangan
                      lainnya.
                    </li>
                    <li>
                      Setiap transaksi dan kegiatan pinjam meminjam atau
                      pelaksanaan kesepakatan mengenai pinjam meminjam antara
                      atau yang melibatkan Penyelenggara,Pemberi Pinjaman
                      dan/atau Penerima Pinjaman wajib dilakukan melalui escrow
                      account dan virtual account sebagai mana yang diwajibkan
                      berdasarkan Peraturan Otoritas Jasa Keuangan
                      Nomor77/POJK.01/2016 tentang Layanan Pinjam Meminjam Uang
                      Berbasis Teknologi Informasi dan pelanggaran atau
                      ketidakpatuhan terhadap ketentuan tersebut merupakan bukti
                      telah terjadinya pelanggaran hukum oleh Penyelenggara
                      sehingga Penyelenggara wajib menanggung ganti rugi yang
                      diderita oleh masing-masing Pengguna sebagai akibat
                      langsung dari pelanggaran hukum tersebut diatas tanpa
                      mengurangi hak Pengguna yang menderita kerugian menurut
                      Kitab Undang-Undang Hukum Perdata.
                      <br />
                      <br />
                      <p className="highlight-footer">
                        PT. Finansial Integrasi Teknologi (“Pinjam Modal”) telah
                        memperoleh Surat Izin Usaha dari Otoritas Jasa Keuangan
                        (OJK) nomor KEP-20/D.05/2020 tanggal 19 Mei 2020 selaku
                        penyelenggara layanan pinjam meminjam uang berbasis
                        teknologi informasi, sehingga pelaksanaan kegiatan
                        usahanya diawasi secara ketat oleh OJK berdasarkan
                        Peraturan Otoritas Jasa Keuangan Nomor 77/POJK.01/2016
                        tentang Layanan Pinjam Meminjam Uang Berbasis Teknologi
                        Informasi
                      </p>
                    </li>
                  </div>
                </Collapse>
              </ol>
              <div className="text-center mt-3">
                <Button
                  className="m-auto py-2 px-3"
                  size="sm"
                  onClick={() => setOpen(!open)}
                  aria-controls="collapse-item"
                  aria-expanded={open}
                  variant="outline-primary"
                >
                  {open ? "Tutup" : "Lihat Selengkapnya"}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-stone py-3">
        <Container className="text-center">
          <span className="copyright" style={{ color: "#fff" }}>
            PT. FINANSIAL INTEGRASI TEKNOLOGI © {e.getFullYear()}
          </span>
        </Container>
      </div>
    </footer>
  );
};

export default MainFooter;
