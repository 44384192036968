import { BaseApiRequest } from "./base-api";
import { OpResult } from "../helper/operation_result";

export default class UserApi extends BaseApiRequest {
  constructor() {
    super(process.env.REACT_APP_API_END_POINT);
  }

  async getLenderProfile() {
    let result = OpResult.failed("Failed To Get Lender Profile");
    try {
      let resApi = await this.get("/lender/profile");
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error Get Lender Profile", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async getLenderPerfomance() {
    let result = OpResult.failed("Failed To Get Lender Profile");
    try {
      let resApi = await this.get("/lender/performance");
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error Get Lender Performance", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async registerInitialNewUser(payload) {
    let result = OpResult.failed("Failed To Register Initial New User");
    try {
      let resApi = await this.post("/register/initial", payload);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error To Register Initial New User", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async resetPassword(payload) {
    let result = OpResult.failed("Failed To Reset Password");
    try {
      let resApi = await this.post("/password/reset", payload);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error To Reset Password", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async changePassword(payload) {
    let result = OpResult.failed("Failed To Change Password");
    try {
      let resApi = await this.post("/password/change", payload);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error To Change Password", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async checkRegistrationDuplicate(payload) {
    let result = OpResult.failed("Failed To Check Duplicate Registration");
    try {
      let resApi = await this.post("/register/duplication-check", payload);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error Check Duplicate Registration", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async updateRegistrationDetail(payload) {
    if (payload.lenderType === 2) {
      delete payload["investmentProduct"];
    }
    let result = OpResult.failed("Failed To Update Registration Detail");
    try {
      let resApi = await this.post("/register/detail/update", payload);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error Update Registration Detail", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async sendOTP(payload) {
    let result = OpResult.failed("Failed To Send OTP");
    try {
      let resApi = await this.post("/otp/send", payload);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error To Send OTP", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }

  async verifyOTP(payload) {
    let result = OpResult.failed("Failed To Send OTP");
    try {
      let resApi = await this.post("/otp/verify", payload);
      if (resApi.status) {
        result = resApi;
      }
    } catch (e) {
      console.log("Error To Send OTP", e);
      result = OpResult.failed("Internal Error");
    }
    return result;
  }
}
