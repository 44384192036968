import JWT from "jsonwebtoken";

const util = {
  thousandSeparator: (x) => {
    try {
      var parts = x.toString().split(",");
      parts[0] = "Rp " + parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    } catch (e) {
      return null;
    }
  },
  thousandSeparatorPure: (x) => {
    try {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } catch (e) {
      return null;
    }
  },
  jwtDecode: (token) => {
    const jwtSecret = process.env.REACT_APP_JWT_SECRET;
    let data = {
      email: null,
      userId: null,
      fullName: null,
      lenderId: null,
    };
    JWT.verify(token, jwtSecret, (error, decoded) => {
      if (!error && decoded) {
        data = {
          email: decoded.data.email,
          userId: decoded.data.userId,
          fullName: decoded.data.fullName,
          lenderId: decoded.data.lenderId,
        };
      }
    });
    return data;
  },
  jwtHashed: (token) => {
    var encodedToken = JWT.sign(
      { authorization: token },
      process.env.REACT_APP_JWT_SECRET,
      {
        expiresIn: "1m",
      }
    );
    return encodedToken;
  },
  jwtCreate: (data) => {
    var token = JWT.sign(data, process.env.REACT_APP_JWT_SECRET);
    return token;
  },
  jwtVerify: (token) => {
    let data = null;
    JWT.verify(token, process.env.REACT_APP_JWT_SECRET, (error, decoded) => {
      if (!error && decoded) {
        data = decoded;
      }
    });
    return data;
  },
  currencySeparator: (x) => {
    x = String(x).replace(/^0+/, "");
    const addCommas = (num) =>
      num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
    return addCommas(removeNonNumeric(x));
  },
  currencySeparatorPure: (x) => {
    return parseInt(String(x).replace(/[.]/g, ""));
  },
  jsonParse: (data) => {
    try {
      data = JSON.parse(data);
      return data;
    } catch (error) {
      return data;
    }
  },
};

export default util;
