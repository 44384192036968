const initState = {
  lenderType: null,
  email: null,
  fullName: null,
  genderId: null,
  mobileNumber: null,
  placeOfBirth: null,
  dateOfBirth: null,
  maritalStatusId: null,
  identificationNumber: null,
  npwpNumber: null,
  lenderAddress: null,
  lenderProvince: null,
  lenderCity: null,
  lenderDistrict: null,
  lenderSubDistrict: null,
  cardHolderName: null,
  bankAccountNumber: null,
  industryTypeId: null,
  businessEntityTypeId: null,
  salary: null,
  companyName: null,
  noAktaPendirian: null,
  tanggalBerdiri: null,
  lenderStatus: null,
  emailValidation: null,
  religionId: null,
  lenderZip: null,
  bankId: null,
  lendingAwareness: {
    sosial_media: false,
    iklan: false,
    teman_kerabat: false,
    media: false,
    lainnya: false,
  },
  lendingAwarenessOthers: null,
  investmentProduct: {
    reksadana: false,
    deposito: false,
    usaha: false,
    lainnya: false,
  },
  investmentProductOthers: null,
};

export const profile = (state = initState, action) => {
  switch (action.type) {
    case "SET_STATE_PROFILE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
