import { Modal, Button } from "react-bootstrap";
import ClearCache from "react-clear-cache";

const ModalUpdateVersion = ({ children }) => {
  return (
    <div className="content">
      <ClearCache>
        {({ isLatestVersion, emptyCacheStorage }) => (
          <div>
            {!isLatestVersion && (
              <div>
                <Modal
                  show={!isLatestVersion}
                  backdrop="static"
                  keyboard={false}
                  size="sm"
                  centered
                >
                  <Modal.Body>
                    <p className="text-center">
                      Demi kenyamanan terbaik untuk menggunakan website ini.
                      Silakan klik tombol
                      <br />
                      <b>perbarui versi</b>.
                    </p>
                    <Button
                      block
                      onClick={(e) => {
                        e.preventDefault();
                        emptyCacheStorage();
                        window.location.reload();
                      }}
                    >
                      Perbarui Versi
                    </Button>
                  </Modal.Body>
                </Modal>
              </div>
            )}
          </div>
        )}
      </ClearCache>
    </div>
  );
};

export default ModalUpdateVersion;
